import { inject, Injectable } from '@angular/core';
import { crmCollectResponse, crmCollectResponseMap } from 'common-module/core';
import { CrmDictionary } from 'common-module/core/types';
import {
  CrmEndpoint,
  CrmEndpointDecorator,
  CrmEndpointListResponse,
} from 'common-module/endpoint';
import { compact, difference, differenceBy, uniq } from 'lodash-es';
import {
  combineLatestWith,
  firstValueFrom,
  forkJoin,
  map,
  Observable,
  of,
  OperatorFunction,
  switchMap,
} from 'rxjs';

import { selectDocumentTemplateFn } from '~/shared/modal/document/select-template/select-document-template';
import { openSendEmailModal } from '~/shared/modal/email/open-send-email-modal';
import { saveFile } from '~/shared/utils/file/save-file';
import { safeListRecordsByIDs } from '~/shared/utils/list/safe-list';

import { DocumentModel } from '../documents/document.model';
import { DocumentsApiService } from '../documents/documents-api.service';
import { DocumentsTemplatesApiService } from '../documents/documents-templates-api.service';
import { EventModel } from '../events/event.model';
import { EventStatus } from '../events/event.status';
import { findXProp } from '../events/event.xprops';
import { EventsApiService } from '../events/events-api.service';
import { NotificationsApiService } from '../notifications/notifications-api.service';
import { PatientModel } from '../patient/patient.model';
import { PatientsApiService } from '../patient/patients-api.service';
import { UserApiService } from '../user/user-api.service';
import { UserModel } from '../user/user.model';

import { BaseRecordModel } from './base-record.model';
import { RecordJournalModel } from './record-journal.model';
import { RecordStatus } from './record.status';
import { RecordType } from './record.type';

@Injectable({ providedIn: 'root' })
export class RecordApiService<Record extends BaseRecordModel> {
  @CrmEndpointDecorator({
    configName: 'crm',
    endpointName: 'records',
  })
  protected readonly endpoint!: CrmEndpoint<Record>;

  type?: RecordType;

  private userApiService = inject(UserApiService);
  private eventsApiService = inject(EventsApiService);
  private documentsApiService = inject(DocumentsApiService);
  private patientsApiService = inject(PatientsApiService);
  private templatesApiService = inject(DocumentsTemplatesApiService);
  private notificationsApiService = inject(NotificationsApiService);

  private selectTemplate = selectDocumentTemplateFn();
  private sendEmailModal = openSendEmailModal();

  get(id: string) {
    return this.endpoint.read(id);
  }

  list(params?: CrmDictionary) {
    return this.endpoint.list({ params: { ...params, type: this.type } });
  }

  listData(params?: CrmDictionary) {
    return this.list(params).pipe(map(({ data }) => data));
  }

  listAll(params?: CrmDictionary) {
    return this.endpoint.listAll({ params: { ...params, type: this.type } });
  }

  create<Body>(body: Body) {
    return this.endpoint.create(body);
  }

  update<Body>(id: string, body: Body) {
    return this.endpoint.update(id, body);
  }

  download({ record, patient }: { record: Record; patient?: PatientModel }) {
    this.patientsApiService
      .resolvePatientWithUndefinedOnError(patient ?? record.patient)
      .pipe(
        combineLatestWith(this.selectTemplate('record')),
        switchMap(([resolvedPatient, template]) =>
          this.getFilename({ record, patient: resolvedPatient }).pipe(
            map((filename) => ({
              filename,
              record,
              patient,
              template,
            })),
          ),
        ),
        switchMap(
          ({
            record: resolvedRecord,
            patient: resolvedPatient,
            template,
            filename,
          }) =>
            this.templatesApiService
              .downloadDocument(template._id, {
                load: {
                  record: resolvedRecord._id,
                  patient: resolvedPatient?._id ?? record.patient,
                },
              })
              .pipe(map((response) => ({ response, filename }))),
        ),
      )
      .subscribe(({ filename, response }) => {
        saveFile(response, 'application/pdf', filename);
      });
  }

  sendByEmail({
    record,
    patient,
    patrons,
    files,
  }: {
    record: Record;
    patient?: PatientModel;
    patrons?: UserModel[];
    files?: string[] | DocumentModel[];
  }) {
    forkJoin({
      resolvedFiles: this.documentsApiService.resolveFiles(files),
      resolvedPatrons: this.userApiService.resolveUsers(patrons),
      filename: this.getFilename({ record, patient }),
    })
      .pipe(
        switchMap(({ resolvedFiles, resolvedPatrons, filename }) =>
          this.sendEmailModal({
            title: 'medicalRecord.modal.sendRecord.title',
            data: {
              recipient: resolvedPatrons.map(({ email }) => email).join(','),
              saveTitle: 'medicalRecord.modal.sendRecord.send',
              attachments: [filename, ...resolvedFiles.map(({ name }) => name)],
              request: (data) =>
                this.notificationsApiService.sendRecord(record._id, {
                  message: data.message,
                  subject: data.subject,
                  to: data.recipient,
                  ids: resolvedPatrons.map((_id) => _id),
                }),
            },
          }),
        ),
      )
      .subscribe();
  }

  getFilename({
    record,
    patient,
  }: {
    record: Record;
    patient?: PatientModel;
  }): Observable<string> {
    return this.patientsApiService
      .resolvePatientWithUndefinedOnError(patient ?? record.patient)
      .pipe(
        map((resolvedPatient) => {
          const { title } = record;
          const patientName = resolvedPatient?.name ?? 'N/A';

          return `${patientName} - ${title}.pdf`;
        }),
      );
  }

  journal(id: string, params?: CrmDictionary) {
    return this.endpoint.request<
      CrmEndpointListResponse<RecordJournalModel<Record>>
    >('GET', [id, 'journal'].join('/'), {
      params,
    });
  }

  updateRelatedRecordsOperator(
    previousRelated: string[],
  ): OperatorFunction<Record, Record> {
    return switchMap(async (record) => {
      const current = record.related ?? [];
      const added = difference(current, previousRelated);
      const removed = difference(previousRelated, current);

      await firstValueFrom(
        crmCollectResponse((currentId) => {
          const _update = () =>
            this.update(currentId, {
              related: current
                .filter((v) => v !== currentId)
                .concat(record._id),
            });

          if (added.includes(currentId)) {
            return this.get(currentId).pipe(
              switchMap((currentRecord) => {
                const remaining = (currentRecord.related ?? []).filter(
                  (v) => !added.includes(v),
                );

                return crmCollectResponse(
                  (remainingId) =>
                    this.update(remainingId, {
                      related: remaining.filter((v) => v !== remainingId),
                    }),
                  remaining,
                ).pipe(switchMap(() => _update()));
              }),
            );
          }

          return _update();
        }, current),
      );

      await firstValueFrom(
        crmCollectResponse(
          (removedId) =>
            this.update(removedId, {
              related: removed.filter((r) => r !== removedId),
            }),
          removed,
        ),
      );

      return firstValueFrom(of(record));
    });
  }

  resolveRelatedRecords(related?: string): Observable<Record[]> {
    if (!related) {
      return of([]);
    }

    return this.get(related).pipe(
      switchMap(({ related: relatedIds = [] }) =>
        safeListRecordsByIDs(this, uniq([related, ...relatedIds])),
      ),
    );
  }

  updateLinkedAppointmentsStatusOperator(): OperatorFunction<Record, Record> {
    return switchMap((record: Record) => {
      return crmCollectResponse(
        (id) =>
          this.eventsApiService.get({
            id,
            principal: record.patient!,
            type: 'default',
          }),
        record.appointments ?? [],
      ).pipe(
        map((events) => compact(events)),
        switchMap((events) => {
          return crmCollectResponseMap(
            (event) => this.listAll({ appointments: event.uid }),
            events,
            'uid',
          ).pipe(
            switchMap((recordsGroup) =>
              crmCollectResponse(
                (uid) =>
                  this.updateEventByRecords(
                    events.find((e) => e.uid === uid)!,
                    recordsGroup[uid],
                  ),
                Object.keys(recordsGroup),
              ).pipe(map(() => record)),
            ),
          );
        }),
      );
    });
  }

  updateRecordsAppointmentsByEvent<Model extends EventModel>(
    event: Model,
    currentIds: string[],
  ) {
    const patient = findXProp(event, 'PATIENT');

    if (!patient) {
      return of(event);
    }

    return forkJoin({
      current: safeListRecordsByIDs(this, currentIds),
      previous: this.listAll({ appointments: event.uid, patient }),
    }).pipe(
      switchMap(({ previous, current }) => {
        const removed = differenceBy(previous, current, '_id');
        const added = differenceBy(current, previous, '_id');

        const remove$ = crmCollectResponse(({ _id: id }) => {
          const record = previous.find((r) => r._id === id);
          const appointments = (record?.appointments ?? []).filter(
            (a) => a !== event.uid,
          );
          return this.update(id, { appointments });
        }, removed);

        const add$ = crmCollectResponse(({ _id: id }) => {
          const record = current.find((r) => r._id === id);
          const appointments = [...(record?.appointments ?? []), event.uid];
          return this.update(id, { appointments });
        }, added);

        return remove$.pipe(combineLatestWith(add$)).pipe(map(() => current));
      }),
      switchMap((current) => this.updateEventByRecords(event, current)),
    );
  }

  private updateEventByRecords<Model extends EventModel>(
    event: Model,
    records: Record[],
  ) {
    const patient = findXProp(event, 'PATIENT');

    if (!patient || !records?.length) {
      return of(event);
    }

    let resolvedStatus: EventStatus = 'COMPLETED';

    const startedStatuses: RecordStatus[] = [
      'closed_correctly',
      'closed_incorrectly',
    ];

    if (records.some(({ status }) => !startedStatuses.includes(status))) {
      resolvedStatus = 'STARTED';
    }

    if (resolvedStatus === event.status) {
      return of(event);
    }

    return this.eventsApiService
      .changeEventStatus({
        event,
        principal: patient,
        status: resolvedStatus,
        type: 'default',
      })
      .pipe(map(() => event));
  }
}
